// $template-padding: 12px !default;
// $template-margin: 6px !important;


$primary-color: #54ADFF;
$bg-color: #D4EAFF;

// $template-padding: -2px !default;
// $template-margin: -2px !default;
// $cell-padding:   12px 20px;
// $cell-font-size: 2rem;
body {
    // background: green;
}

@import '@silevis/reactgrid/styles.scss';