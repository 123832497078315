.air-datepicker {
    --adp-cell-background-color-selected: theme('colors.black');
    --adp-background-color: inherit;
    --adp-background-color-active: theme('colors.gray-50');
    --adp-font-family: inherit;
    --adp-font-size: 14px;
    --adp-width: auto;
    --adp-z-index: 100;
    --adp-background-color: #fff;
    --adp-background-color-hover: theme('colors.gray-50');
    --adp-cell-background-color-selected-hover: theme('colors.orange-500');
    --adp-cell-background-color-in-range-hover: theme('colors.orange-200');
    --adp-background-color-selected-other-month-focused: theme('colors.gray-800');
    --adp-background-color-selected-other-month: theme('colors.black');
    --adp-color: theme('colors.gray-700');
    --adp-color-secondary: theme('colors.gray-500');
    --adp-color-current-date: var(--adp-accent-color);
    --adp-color-other-month: #dedede;
    --adp-color-disabled: theme('colors.gray-200');
    --adp-color-disabled-in-range: #939393;
    --adp-color-other-month-hover: #c5c5c5;
    --adp-border-color: theme('colors.gray-200');
    --adp-border-color-inner: #efefef;
    --adp-border-radius: 8px;
    --adp-border-color-inline: theme('colors.gray-200');
    --adp-day-name-color: theme('colors.gray-700');
    --adp-day-cell-width: calc((100% - 24px) / 7);
    --adp-day-cell-height: 36px;

    @media screen and (min-width: 1280px) {
        --adp-day-cell-width: calc((100% - 48px) / 7);
        --adp-day-cell-height: 42px;
    }

    border: 0;
    background: inherit;
    border-radius: 0;
    font-size: 14px;

    &-overlay {
        --adp-overlay-background-color: rgba(0, 0, 0, .3);
        --adp-overlay-transition-duration: .3s;
        --adp-overlay-transition-ease: ease-out;
        --adp-overlay-z-index: 99
    }

    &-nav {
        border: 0
    }

    &--content {
        padding: 0;
    }

    &-body {
        &--day-names {
            gap: 4px;

            @media screen and (min-width: 1280px) {
                gap: 8px;
            }
        }

        &--cells {
            grid-auto-rows: 48px;
            gap: 4px;

            @media screen and (min-width: 1280px) {
                gap: 8px;
            }

            &.-months- {
                gap: 12px;
                padding: 16px;
            }

            &.-days- {
                width: 100%;
                padding: 8px;
                background: theme('colors.white');
                border-radius: 8px;
                border: 2px solid theme('colors.gray-200');

                @media screen and (min-width: 1280px) {
                    padding: 16px;
                }
            }
        }
    }

    &-cell {
        font-weight: 500;
        color: theme('colors.gray-700');
        background-color: theme('colors.gray-50');
        border: 2px solid theme('colors.gray-200');

        &.-month- {
            border-radius: 8px;
            padding-top: 8px;
            padding-bottom: 8px;
            color: theme('colors.gray-700');
        }

        &.-other-month- {
            background: #FFF;
            border-color: theme('colors.gray-100');
            color: theme('colors.gray-500');
        }

        &.-range-from-,
        &.-range-to- {
            border-radius: 8px;
        }

        &.-in-range- {
            border-radius: 8px;
            transition: 250ms ease;

        }

        &.-selected- {
            background: theme('colors.black');
            transition: 250ms ease;

            &:hover {
                background: theme('colors.gray-800');
            }
        }
    }
}

.service_id_1 {
    .air-datepicker {
        --adp-background-color-hover: theme('colors.orange-100');
        --adp-cell-background-color-selected-hover: theme('colors.orange-500');
        --adp-cell-background-color-in-range: theme('colors.orange-100');
        --adp-cell-border-color-in-range: theme('colors.orange-100');
        --adp-cell-background-color-in-range-hover: theme('colors.orange-200');
        --adp-background-color-in-range: theme('colors.orange-100');
        --adp-accent-color: theme('colors.orange-500');
        --adp-day-name-color-hover: theme('colors.orange-300');


        &-cell {
            &.-in-range- {
                border-color: theme('colors.orange-100');

                &:hover {
                    background: theme('colors.orange-200');
                }
            }

            @media screen and (min-width: 992px) {
                &.-focus- {
                    background: theme('colors.orange-100');
                    border-color: theme('colors.orange-600');

                }
            }
        }
    }
}

.service_id_2 {
    .air-datepicker {
        --adp-background-color-hover: theme('colors.blue-100');
        --adp-cell-background-color-selected-hover: theme('colors.blue-500');
        --adp-cell-background-color-in-range: theme('colors.blue-100');
        --adp-cell-border-color-in-range: theme('colors.blue-100');
        --adp-cell-background-color-in-range-hover: theme('colors.blue-200');
        --adp-background-color-in-range: theme('colors.blue-100');
        --adp-accent-color: theme('colors.blue-500');
        --adp-day-name-color-hover: theme('colors.blue-300');


        &-cell {
            &.-in-range- {
                border-color: theme('colors.blue-100');

                &:hover {
                    background: theme('colors.blue-200');
                }
            }

            &.-focus- {
                background: theme('colors.blue-100');
                border-color: theme('colors.blue-600');

            }
        }
    }
}