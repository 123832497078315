@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@layer base {

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    html {
        @apply text-gray-800;
    }
}

@media screen and (max-width: 1280px) {
    body {
        padding-right: 0 !important;
    }
}
@media screen and (min-width: 1280px) {

    /* СКРОЛЛБАР  */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        /* width: 6px;
    height: 6px; */
        cursor: pointer;
    }

    ::-webkit-scrollbar-track {
        background-color: theme('colors.gray-100');
    }

    ::-webkit-scrollbar-thumb {
        background: theme('colors.gray-600');
        border-radius: 10px;
    }

}

body {
    /* overflow: scroll; */
    /* scrollbar-color: red orange; */
    /* scrollbar-width: thin; */
    /* padding-right: 20px; */
    overflow-x: hidden;
    min-height: 100vh;
    background: theme('colors.gray-50');
}

* {
    outline: 0 !important
        /* outline: blue 1px solid; */
}

.service_id_1 *:focus-visible {
    outline-color: theme('colors.orange-500');
    /* outline: none  */
}

.service_id_2 *:focus-visible {
    outline-color: theme('colors.blue-500');
    /* outline: none  */
}

.service_id_1 ::-webkit-scrollbar-thumb {
    background: theme('colors.orange-400');
}

.service_id_2 ::-webkit-scrollbar-thumb {
    background: theme('colors.blue-500');
}

.svg-checkbox-dark svg {
    fill: theme('colors.gray-200')
}

.svg-checkbox-dark input:checked+svg {
    fill: theme('colors.gray-800')
}

.svg-sort-orange svg {
    fill: theme('colors.orange-600')
}

.svg-sort-blue svg {
    fill: theme('colors.blue-500')
}

.table-row-border {
    position: relative;
    transition: 250ms ease;
}

.table-row-border::after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    /* background-color: red */
    background-color: theme('colors.gray-100')
}

.table-row-border:last-child::after {
    display: none;
}

.table-row-border.Mui-selected.service_id_1 {
    background-color: theme('colors.orange-50')
}

.table-row-border.Mui-selected.service_id_2 {
    background-color: theme('colors.blue-100')
}

.table-row-border.Mui-selected .table-row-number.service_id_1,
.table-row-border:hover .table-row-number.service_id_ {
    background-color: theme('colors.orange-100')
}

.table-row-border.Mui-selected .table-row-number.service_id_2,
.table-row-border:hover .table-row-number.service_id_2 {
    background-color: theme('colors.blue-200');
    color: theme('colors.blue-700')
}


.table-head-border {
    position: relative;
}

.table-head-border th {
    padding-bottom: 0;
}

.table-head-border::after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 1px;
    width: 100%;
    /* background-color: red */
    background-color: theme('colors.gray-100')
}

.Spreadsheet__table {
    border-collapse: separate !important;
    border-spacing: 8px;
}

.Spreadsheet__active-cell,
.Spreadsheet__floating-rect--selected {
    border-radius: 8px !important;
    border-color: theme('colors.gray-400') !important;
    font-size: 14px;
}

td.Spreadsheet__cell {
    border-radius: 5px;
    min-height: 32px !important;
    border-color: theme('colors.gray-100');
    border-width: 2px;
}

td.Spreadsheet__cell span {
    padding-left: 12px;
    padding-right: 8px;
}


.rg-fill-handle {
    background: theme('colors.orange-600') !important;
}

/* инпут ячейки */
.rg-celleditor {
    border: 0 !important;
    outline: 2px solid theme('colors.orange-400') !important;
    outline-offset: -2px !important;
    border-radius: 5px;
    /* padding-right: 8px !important; */
    background: theme('colors.white');
    box-shadow: none;

}

.rg-celleditor.rg-number-celleditor input {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
}

.hide-input .rg-celleditor,
.hide-input input {
    display: none !important;
}

/* сфокусированная одна ячейка */
.reactgrid-content .rg-pane .rg-cell-focus,
.reactgrid-content .rg-pane .rg-cell-highlight {

    border: 0 !important;
    outline: 2px solid theme('colors.orange-400') !important;
    outline-offset: -4px !important;
    border-radius: 12px;
}

/* сфокусированная область */
.reactgrid-content .rg-pane .rg-partial-area.rg-partial-area-selected-range {
    border: 0 !important;
    box-shadow: none !important;
    outline: 2px solid theme('colors.orange-400') !important;
    outline-offset: -4px !important;
    border-radius: 5px;
    background: none !important;
}


/* все ячейки */
.asp-cell {
    justify-content: center !important;
    border: 0 !important;
    outline: 2px solid theme('colors.gray-100') !important;
    outline-offset: -4px !important;
    border-radius: 10px;
    font-size: 14px !important;
    color: theme('colors.gray-800') !important;
    font-weight: 700 !important;
    box-shadow: none !important;
}

.asp-cell.stripped {
    background-color: theme('colors.gray-100') !important;
    outline: 4px solid theme('colors.white') !important;
    outline-offset: -2px !important;
}

.asp-cell-date,
.asp-cell-header {
    border: 0 !important;
    background: none !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: theme('colors.gray-500') !important;
    text-align: center !important;
    box-shadow: none !important;
    justify-content: center !important;
}

.reactgrid-content .rg-pane {
    box-shadow: none !important;
    background-color: theme('colors.white') !important;
}

.reactgrid-content .rg-pane-shadow {
    box-shadow: none !important;
}

.reactgrid-content .rg-pane.rg-pane-left {
    left: -24px !important;
}

@keyframes appear {
    from {
        transform: translateY(5px);
        opacity: 0;
    }

    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.animate-appear {
    animation: appear;
    /* animation-timing-function: ease-in; */
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    animation-duration: 200ms;
    animation-fill-mode: backwards;
}

/* body { 
    --switch-ee: #FFC5AF;
    --badge-red: #F87171;
    --badge-orange: #FAAA4C;
    --badge-green: #4DDC4D;
    --badge-blue: #4FBFEF;
    --badge-purple: #827FFF;
    --ivent-plan: #827FFF;
    --ivent-final: #4FBFEF;
    --ivent-act: #4DDC4D;
    --ivent-payment: #FAAA4C;
    --ivent-change: #F87171;
    --blue-50: #FDFDFF;
    --blue-100: #EEF4FF;
    --blue-200: #D2E1FF;
    --blue-300: #B6CDFF;
    --blue-400: #9ABAFF;
    --blue-500: #6293FF;
    --blue-600: #4680FF;
    --blue-700: #0E53E8;
    --red-600: #B91C1C;
    --red-500: #EF4444;
    --red-400: #F87171;
    --red-300: #FCA5A5;
    --red-200: #FFC8C8;
    --red-100: #FEE2E2;
    --red-50: #FFF0F0;
    --gray-800: #2D2B32;
    --gray-700: #444248;
    --gray-600: #767579;
    --gray-500: #9B9A9D;
    --gray-400: #BFBFC1;
    --gray-300: #D1D1D2;
    --gray-200: #E4E4E4;
    --gray-100: #EDEDED;
    --gray-50: #F6F6F6;
    --green-600: #139A32;
    --green-500: #33AB4F;
    --green-400: #53BC6B;
    --green-300: #73CD88;
    --green-200: #92DDA4;
    --green-100: #B2EEC1;
    --green-50: #D2FFDD;
    --orange-600: #F25219;
    --orange-500: #FF7340;
    --orange-400: #FF8E65;
    --orange-300: #FFAA8B;
    --orange-200: #FFD0BE;
    --orange-100: #FFDFD3;
    --orange-50: #FFF2EC; 
    --white-cabinet: #FDFDFD;
    --black-cabinet: #1D1C20
}
body.dark { 
    --switch-ee: #FFC5AF;
    --badge-red: #F87171;
    --badge-orange: #FAAA4C;
    --badge-green: #4DDC4D;
    --badge-blue: #4FBFEF;
    --badge-purple: #827FFF;
    --ivent-plan: #827FFF;
    --ivent-final: #4FBFEF;
    --ivent-act: #4DDC4D;
    --ivent-payment: #FAAA4C;
    --ivent-change: #F87171;
    --blue-700: #FDFDFF;
    --blue-600: #EEF4FF;
    --blue-500: #D2E1FF;
    --blue-400: #B6CDFF;
    --blue-300: #9ABAFF;
    --blue-200: #6293FF;
    --blue-100: #4680FF;
    --blue-50: #0E53E8;
    --red-50: #B91C1C;
    --red-100: #EF4444;
    --red-200: #F87171;
    --red-300: #FCA5A5;
    --red-400: #FFC8C8;
    --red-500: #FEE2E2;
    --red-600: #FFF0F0;
    --gray-800: #D1D1D2;
    --gray-700: #D1D1D2;
    --gray-600: #D1D1D2;
    --gray-500: #BFBFC1;
    --gray-400: #9B9A9D;
    --gray-300: #767579;
    --gray-200: #55545b;
    --gray-100: #2D2B32;
    --gray-50: #55545b;
    --green-50: #139A32;
    --green-100: #33AB4F;
    --green-200: #53BC6B;
    --green-300: #73CD88;
    --green-400: #92DDA4;
    --green-500: #B2EEC1;
    --green-600: #D2FFDD;
    --orange-50: #F25219;
    --orange-100: #FF7340;
    --orange-200: #FF8E65;
    --orange-300: #FFAA8B;
    --orange-400: #FFD0BE;
    --orange-500: #FFDFD3;
    --orange-600: #FFF2EC; 
    --white-cabinet: #3f3d47;
    --black-cabinet: #f3c0c0;
} */